import React, { useMemo, useState, useRef } from 'react'
import classNames from 'classnames/bind'

import ViewportEnter from 'components/core/ViewportEnter'
import useUIContext from 'context/ui'

import { MarqueeProps } from './MarqueeTypes'
import Segment from './Segment'
import * as s from './Marquee.module.scss'

const cn = classNames.bind(s)

const _ = {
  speed: 50, //sec
  space: '5vw', //space between segments
}

const Marquee = ({ repeatsPerInstance = 1, speed, space, ...props }: MarqueeProps) => {
  const [isInView, setIsInView] = useState(false)
  const repeatsArray = useMemo(() => [...Array(repeatsPerInstance).keys()], [repeatsPerInstance])
  const isFirstView = useRef(false)
  const isLoaded = useUIContext(s => s.isLoaded)

  const onEnter = () => {
    isFirstView.current = true
    setIsInView(true)
  }

  const onExit = () => {
    setIsInView(false)
  }

  if (!isLoaded) return <Segment className={'hidden'} repeatsArray={repeatsArray} {...props} /> //remount on load to avoid cut-offs
  return (
    <ViewportEnter onEnter={onEnter} onExit={onExit} once={false}>
      <div
        key={isLoaded.toString()} //force remount to avoid cut-offs
        className={cn('marquee', { isInView, isFirstView: isFirstView.current })}
        style={
          {
            '--speed': speed ? speed : _.speed * repeatsPerInstance,
            '--space': space ? space : _.space,
          } as React.CSSProperties
        }
      >
        <Segment className={'main'} repeatsArray={repeatsArray} {...props} />
        <Segment className={'aux'} repeatsArray={repeatsArray} {...props} />
      </div>
    </ViewportEnter>
  )
}

export default Marquee
