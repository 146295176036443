import React from 'react'

import SEO from 'components/core/SEO'
import Marquee from 'components/ui/Marquee'
import { HeroText } from 'components/ui/Text'

const NotFound = () => {
  return (
    <>
      <SEO title='Marquee' />
      <main style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}>
        <div>
          <Marquee repeatsPerInstance={12} speed={48} space={'-100px'}>
            <svg height='40' viewBox='0 0 334 83' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M168.654 13.7401H158.721C158.321 13.7401 158 13.3998 158 12.9756V1.36309C158 0.54743 157.484 0 156.847 0H147.802C147.165 0 146.649 0.54743 146.649 1.22309V51.6954C146.649 59.8477 149.071 65.9828 153.852 69.8297C158.465 73.5384 163.761 73.9428 165.826 73.9428C166.993 73.9428 168.012 73.8935 168.728 73.8491C169.337 73.8096 169.807 73.2721 169.807 72.626V63.1914C169.807 62.4911 169.258 61.9338 168.602 61.9683C168.235 61.9881 167.826 62.0029 167.444 62.0029C161.074 62.0029 158.237 58.3681 158.237 50.3292V26.2028C158.237 25.7786 158.558 25.4383 158.958 25.4383H168.658C169.295 25.4383 169.811 24.8909 169.811 24.2152V14.9681C169.807 14.2924 169.291 13.7401 168.654 13.7401Z'
                fill='white'
              />
              <path
                d='M136.321 47.0614C136.321 63.2765 125.176 74.0997 111.411 74.0997C97.6419 74.0997 87.0446 61.7331 87.0446 47.0614V15.0419C87.0446 14.3642 87.5594 13.8151 88.1948 13.8151H97.4842C98.1196 13.8151 98.6344 14.3642 98.6344 15.0419V47.7292C98.6344 55.5498 104.149 61.7331 111.407 61.7331C118.66 61.7331 124.722 55.5498 124.722 47.7292V15.0419C124.722 14.3642 125.056 13.8151 125.696 13.8151H135.166C135.801 13.8151 136.316 14.3642 136.316 15.0419L136.321 47.0614Z'
                fill='white'
              />
              <path
                d='M74.2328 0.157303H64.8909C64.2519 0.157303 63.7343 0.705288 63.7343 1.38163V72.7187C63.7343 73.3951 64.2519 73.9431 64.8909 73.9431H74.2328C74.8717 73.9431 75.3894 73.3951 75.3894 72.7187V1.38163C75.3894 0.705288 74.8717 0.157303 74.2328 0.157303Z'
                fill='white'
              />
              <path
                d='M222.231 21.3424C216.759 15.505 209.44 12.2899 201.619 12.2899C193.799 12.2899 186.48 15.505 181.008 21.3424C175.559 27.1552 172.557 34.904 172.557 43.1564C172.557 51.4089 175.559 59.1576 181.008 64.9704C186.48 70.8079 193.799 74.0229 201.619 74.0229C209.44 74.0229 216.759 70.8079 222.231 64.9704C227.679 59.1576 230.682 51.4089 230.682 43.1564C230.682 34.904 227.679 27.1552 222.231 21.3424ZM201.615 61.6763C192.281 61.6763 184.693 53.3695 184.693 43.1564C184.693 32.9433 192.286 24.6365 201.615 24.6365C210.943 24.6365 218.541 32.9433 218.541 43.1564C218.541 53.3695 210.948 61.6763 201.615 61.6763Z'
                fill='white'
              />
              <circle cx='282' cy='43' r='8' fill='white' />
              <path
                d='M49.5375 20.0094C44.2534 14.7099 37.2482 11.7141 29.7327 11.5144C29.4961 11.5005 28.3317 11.5098 28.0301 11.5191C12.2754 11.9789 6.10352e-05 23.595 6.10352e-05 39.3309V81.4667C6.10352e-05 82.103 0.515004 82.6186 1.15057 82.6186H10.4429C11.0785 82.6186 11.5934 82.103 11.5934 81.4667V64.788C11.5934 64.788 11.5934 63.7522 11.5934 63.8637C16.562 67.5562 22.5791 69.558 28.9301 69.558C36.7472 69.558 44.0632 66.5343 49.5328 61.0444C54.9793 55.5777 57.9808 48.2904 57.9808 40.5292C57.9855 32.7635 54.9839 25.4761 49.5375 20.0094ZM28.9301 57.9418C19.6007 57.9418 12.0156 50.1296 12.0156 40.5246C12.0156 30.9196 19.6053 23.1073 28.9301 23.1073C38.2549 23.1073 45.8493 30.9196 45.8493 40.5246C45.8493 50.1296 38.2596 57.9418 28.9301 57.9418Z'
                fill='white'
              />
            </svg>
          </Marquee>
          <Marquee>
            <HeroText>We help humanity transcend reality.</HeroText>
          </Marquee>
          <Marquee repeatsPerInstance={12} speed={50} space={'-100px'}>
            <svg height='40' viewBox='0 0 334 83' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M168.654 13.7401H158.721C158.321 13.7401 158 13.3998 158 12.9756V1.22309C158 0.54743 157.484 0 156.847 0H147.802C147.165 0 146.649 0.54743 146.649 1.22309V51.6954C146.649 59.8477 149.071 65.9828 153.852 69.8297C158.465 73.5384 163.761 73.9428 165.826 73.9428C166.993 73.9428 168.012 73.8935 168.728 73.8491C169.337 73.8096 169.807 73.2721 169.807 72.626V63.1914C169.807 62.4911 169.258 61.9338 168.602 61.9683C168.235 61.9881 167.826 62.0029 167.444 62.0029C161.074 62.0029 158.237 58.3681 158.237 50.3292V26.2028C158.237 25.7786 158.558 25.4383 158.958 25.4383H168.658C169.295 25.4383 169.811 24.8909 169.811 24.2152V14.9681C169.807 14.2924 169.291 13.7401 168.654 13.7401Z'
                fill='white'
              />
              <path
                d='M136.321 47.0614C136.321 63.2765 125.176 74.0997 111.411 74.0997C97.6419 74.0997 87.0446 61.7331 87.0446 47.0614V15.0419C87.0446 14.3642 87.5594 13.8151 88.1948 13.8151H97.4842C98.1196 13.8151 98.6344 14.3642 98.6344 15.0419V47.7292C98.6344 55.5498 104.149 61.7331 111.407 61.7331C118.66 61.7331 124.722 55.5498 124.722 47.7292V15.0419C124.722 14.3642 125.056 13.8151 125.696 13.8151H135.166C135.801 13.8151 136.316 14.3642 136.316 15.0419L136.321 47.0614Z'
                fill='white'
              />
              <path
                d='M74.2328 0.157303H64.8909C64.2519 0.157303 63.7343 0.705288 63.7343 1.38163V72.7187C63.7343 73.3951 64.2519 73.9431 64.8909 73.9431H74.2328C74.8717 73.9431 75.3894 73.3951 75.3894 72.7187V1.38163C75.3894 0.705288 74.8717 0.157303 74.2328 0.157303Z'
                fill='white'
              />
              <path
                d='M222.231 21.3424C216.759 15.505 209.44 12.2899 201.619 12.2899C193.799 12.2899 186.48 15.505 181.008 21.3424C175.559 27.1552 172.557 34.904 172.557 43.1564C172.557 51.4089 175.559 59.1576 181.008 64.9704C186.48 70.8079 193.799 74.0229 201.619 74.0229C209.44 74.0229 216.759 70.8079 222.231 64.9704C227.679 59.1576 230.682 51.4089 230.682 43.1564C230.682 34.904 227.679 27.1552 222.231 21.3424ZM201.615 61.6763C192.281 61.6763 184.693 53.3695 184.693 43.1564C184.693 32.9433 192.286 24.6365 201.615 24.6365C210.943 24.6365 218.541 32.9433 218.541 43.1564C218.541 53.3695 210.948 61.6763 201.615 61.6763Z'
                fill='white'
              />
              <circle cx='282' cy='43' r='8' fill='white' />
              <path
                d='M49.5375 20.0094C44.2534 14.7099 37.2482 11.7141 29.7327 11.5144C29.4961 11.5005 28.3317 11.5098 28.0301 11.5191C12.2754 11.9789 6.10352e-05 23.595 6.10352e-05 39.3309V81.4667C6.10352e-05 82.103 0.515004 82.6186 1.15057 82.6186H10.4429C11.0785 82.6186 11.5934 82.103 11.5934 81.4667V64.788C11.5934 64.788 11.5934 63.7522 11.5934 63.8637C16.562 67.5562 22.5791 69.558 28.9301 69.558C36.7472 69.558 44.0632 66.5343 49.5328 61.0444C54.9793 55.5777 57.9808 48.2904 57.9808 40.5292C57.9855 32.7635 54.9839 25.4761 49.5375 20.0094ZM28.9301 57.9418C19.6007 57.9418 12.0156 50.1296 12.0156 40.5246C12.0156 30.9196 19.6053 23.1073 28.9301 23.1073C38.2549 23.1073 45.8493 30.9196 45.8493 40.5246C45.8493 50.1296 38.2596 57.9418 28.9301 57.9418Z'
                fill='white'
              />
            </svg>
          </Marquee>
        </div>
      </main>
    </>
  )
}

export default NotFound
