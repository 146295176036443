import React from 'react'
import classNames from 'classnames/bind'

import * as s from './Marquee.module.scss'

const cn = classNames.bind(s)

type SegmentProps = {
  className: string
  repeatsArray: number[]
  children?: React.ReactNode
}

const Segment = ({ className, repeatsArray, children }: SegmentProps) => {
  return (
    <div className={cn('segment', className)}>
      {repeatsArray.map((_, i) => (
        <div key={i}>{children}</div>
      ))}
    </div>
  )
}

export default Segment
